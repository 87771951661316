import React from "react";
import ReactDatePicker, { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import style from "../styles.module.scss";
import { useContext } from "react";
import { ContextTc } from "..";
import { Label } from "structure/Document";
import { RangeDate , FromB , ToB } from "components/general/RangeDate";

registerLocale("es", es);

export default function RangeFilter() {
  const hook = useContext(ContextTc);

    return <RangeDate>
        <FromB
            defaultValue={hook.from}
            onChange={(date) => hook.setFrom(date)}
        />
        <ToB
            defaultValue={hook.to}
            onChange={(date) => hook.setTo(date)}
        />
    </RangeDate>

  return (
    <>
      <div className={style.dateInput}>
        <Label>Desde</Label>
        <ReactDatePicker
          selected={hook.from}
          dateFormat="dd/MMM/yyyy"
          locale="es"
          placeholderText="Selecciona"
          onChange={(date) => hook.setFrom(date)}
          maxDate={hook.to === null ? new Date() : hook.to}
          isClearable={true}
        />
      </div>

      <div className={style.dateInput}>
        <Label required={hook.from !== null && hook.to === null}>Hasta</Label>

        <ReactDatePicker
          selected={hook.to}
          disabled={hook.from === null}
          dateFormat="dd/MMM/yyyy"
          locale="es"
          minDate={hook.from}
          placeholderText={
            hook.from === null ? 'Selecciona "Desde"' : "Selecciona"
          }
          onChange={(date) => hook.setTo(date)}
          maxDate={new Date()}
          isClearable={true}
        />
      </div>
    </>
  );
}
