import React, { useContext } from "react";
import { RangeDate, From, To , FromB , ToB } from "components/general/RangeDate";
import { ContextInvoice } from "../..";
import MonthSelect from "components/general/Forms/Selects/Month";
import YearSelect from "components/general/Forms/Selects/Year";
import date from "./styles.module.scss";

export default function DateFilter() {
  const hook = useContext(ContextInvoice);



  if (hook.from !== "contabilidad")
    return (
      <RangeDate>
        <FromB
          defaultValue={new Date(hook.startDate)}
          onChange={(date)=>hook.updateRangeDates([date, hook.state.date.end])}
        />
        {/* <From
          withPortal={true}
          selected={new Date(hook.startDate)}
          onChange={(date) =>
            hook.updateRangeDates([date, hook.state.date.end])
          }
          
        /> */}
        <ToB
          defaultValue={new Date(hook.endDate)}
          onChange={(date) =>
            hook.updateRangeDates([hook.state.date.start, date])
          }
        />
        {/* <To
          withPortal={true}
          selected={new Date(hook.endDate)}
          onChange={(date) =>
            hook.updateRangeDates([hook.state.date.start, date])
          }
        /> */}
      </RangeDate>
    );


  return (
    <div className={date.container}>
      <YearSelect
        from={new Date().getFullYear() - 10}
        value={hook.state.date.start.getFullYear()}
        onChange={(year) => hook.setYear(year)}
      />
      <MonthSelect
        onChange={hook.setMonth}
        value={hook.state.date.start.getMonth() + 1}
      />
    </div>
  );
}
