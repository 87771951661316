import React from "react";
import CardDocRelated, { EmptyCardMoreInfo } from "../CardDocRelated";
import { ContextDocsRelated } from "..";
import { useContext } from "react";
import { parseToFolio } from "helpers/documents";
import { parseDateToText } from "helpers/dates";

export default function FacturaEmitida() {
  const hook = useContext(ContextDocsRelated);

  const emited = hook.popUpInfo.fe;

  if (emited === null) return <EmptyCardMoreInfo label="Factura emitida" />;

  return (
    <CardDocRelated
      id={emited.id}
      folio={parseToFolio(+emited.folio)}
      endDate={parseDateToText(`${emited.expirationDate.split('T')[0]}:`)}
      customer={emited.customer.socialReason}
      startDate={parseDateToText(`${emited.emitedDate.split('T')[0]}:`)}
      email={emited.customer.contact.email}
      phone={emited.customer.contact.phone.parsed}
      importe={emited.importe.text}
      iva={emited.iva.text}
      total={emited.total.text}
      currency={emited.currency}
      label="Factura emitida"
      concept={emited.concepts[0]?.description||""}
      status={emited.status.description}
    />
  );
}
