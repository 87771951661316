import axios from "axios";
import { dateToDbFormat, getDateFromUtc, parseDateToText } from "helpers/dates";
import { URL_BASE } from "../../routes/routes";
import { DisplayError, Error, HtmlAlert, Success, YesNoAlert } from "../alerts";
import { createLog, createPrettyJsonHtml } from "../errors";
import { getInvoiceFile } from "../facturama/invoice";
import { uploadFileToAzure } from "../files";
import { arrayToQueryParam, isValidHttpResCode } from "./fetch";
import { getDocumentData } from "components/Sections/Documents/helpers/documentHelper";

/**
 * Stamp the pre-invoice with mizar
 *
 * @param {number} idCustomer - Id of the customer to stamp the invoice
 * @param {number} idPreinvoce - Id of the preinvoice to stamp
 * @returns {boolean} True if was stamped the pre-invoice and saved his information
 */
export async function StampInvoice(idCustomer = null, idPreinvoice = null) {
  try {
    const { data } = await axios.post(
      `${URL_BASE}facturas/pre-factura/timbrar`,
      {
        idCustomer,
        idPreinvoice,
      }
    );

    if (data.status !== 200) {
      Error(() => {}, data.message);
      return false;
    }

    console.log(data);

    return true;
  } catch (error) {
    console.log(error);
    Error(() => {}, error);
    return false;
  }
}

/**
 * Update the TC requested by the execute on the stam preinvoice
 * @param {number} idDocument - Id of the preinvoice
 * @param {number} tcRequested - [14,2] TC requested by the executive
 * @returns {boolean} True if tcp was requested success
 */
export async function updateTcRequested(idDocument, tcRequested) {
  try {
    await axios.put(`${URL_BASE}documentos/prefactura/tc`, {
      idDocument,
      tc: tcRequested,
    });

    return true;
  } catch (error) {
    return false;
  }
}

/**
 * Update the info the preinvoice document in case the exchange currency was requested
 * @param {PreinvoiceTcExc} preinvoiceInfo - Info to use on the update preinvoice info
 * @param {object[]} items - Recalculated items with the TC requested
 * @returns {Promise<boolean>} True if updated was success
 */
export async function preinvoiceExchangeCurrency(preinvoiceInfo, items) {
  try {
    const { data } = await axios.put(
      `${URL_BASE}documentos/prefactura/cambio-moneda`,
      {
        ...preinvoiceInfo,
        items,
      }
    );

    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
}

/**
 * Create the information of invoice on the enterprise system
 *
 * @param {object} information - Information to create the invoice
 * @returns {Promise<boolean>} True if information was created
 */
export async function createInvoiceEnterprise(information) {
  try {
    const { data } = await axios.post(
      `${URL_BASE}documentos/factura`,
      information,
      {
        withCredentials: true,
      }
    );

    if (data.status === 200 && data.wasCreated) {
      return true;
    }

    return false;
  } catch (error) {
    return false;
  }
}

/**
 * Sync the files created for the invoice with internal system
 * @param {import("types/typedef/invoice").SyncInvoiceFilesI} info - Information to sync the files
 */
export async function uploadFilesInvoiceCreatedV2({
  idPreinvoice,
  invoiceNumber,
  uuid,
  documentNumber,
  idProvider,
  executive,
}) {
  try {
    const [pdf, xml] = await Promise.all([
      getInvoiceFile(idProvider, "pdf"),
      getInvoiceFile(idProvider, "xml"),
    ]);

    const formData = new FormData();
    formData.append("pdf", pdf);
    formData.append("xml", xml);
    formData.append(
      "data",
      JSON.stringify({
        idPreinvoice,
        invoiceNumber,
        uuid,
        idProvider,
        executive,
        documentNumber,
      })
    );

    const { data } = await axios.post(
      `${URL_BASE}facturas/pre-factura/sincronizar-archivos`,
      formData,
      {
        withCredentials: true,
      }
    );

    return {
      pdf: null,
      xml: null,
      filesSync: true,
      idProviderInvoice: idProvider,
      updatedBy: executive.fullName,
      invoiceNumberSupplier: invoiceNumber,
    };
  } catch (error) {
    throw {
      filesSync: false,
      codeError: "A1",
      message: `El Proceso de facturación ha sido completado, sin embargo, no fue posible realizar la sincronización de datos entre “Praxia” y el sistema de facturación.
        En breve el equipo de soporte realizará el proceso de sincronización y usted recibirá un mail de confirmación.`,
      errorDetails: error,
      step: 2,
    };
  }
}

/**
 * Upload the files (pdf and xml) of the invoice generated
 * @param {(number|string)} idPreinvoice - Id of the preinvoice that was won
 * @param {(number|string)} invoiceNumber - Number of the invoice
 * @param {string} idPreinvoiceProvider - Id of the invoice that provider assigned
 * @param {string} uuid - UUID of the invoice generated
 * @param {string} createdBy - [30] Name executive who created the invoice
 * @returns {{
 *  pdf:AzureFileUploaded,
 *  xml:AzureFileUploaded
 * }} URL info where the files are saved
 * @example uploadFilesInvoiceCreated(531,"00003","i3JETbvJNE6Zcq-smQSqfg2")
 */
export async function uploadFilesInvoiceCreated(
  idPreinvoice,
  invoiceNumber,
  idPreinvoiceProvider,
  uuid = "123",
  createdBy = "??????"
) {
  try {
    const files = await Promise.all([
      await getInvoiceFile(idPreinvoiceProvider, "pdf"),
      await getInvoiceFile(idPreinvoiceProvider, "xml"),
    ]);

    const queuUpload = await Promise.all([
      uploadFileToAzure(
        files[0],
        `/Documentos/${idPreinvoice}`,
        `PDF-Factura-${invoiceNumber}`,
        "pdf",
        (error) => {
          console.log(error);
          Error(
            () => {},
            `El Proceso de facturación ha sido completado, sin embargo, no fue posible realizar la sincronización de datos entre “Praxia” y el sistema de facturación.
          En breve el equipo de soporte realizará el proceso de sincronización y usted recibirá un mail de confirmación.
          `
          );
        }
      ),
      uploadFileToAzure(
        files[1],
        `/Documentos/${idPreinvoice}`,
        `XML-Factura-${invoiceNumber}`,
        "xml",
        (error) => {
          console.log(error);
          Error(
            () => {},
            `El Proceso de facturación ha sido completado, sin embargo, no fue posible realizar la sincronización de datos entre “Praxia” y el sistema de facturación.
          En breve el equipo de soporte realizará el proceso de sincronización y usted recibirá un mail de confirmación.`
          );
        }
      ),
    ]);

    const infoFilesUploaded = {
      pdf: queuUpload[0],
      xml: queuUpload[1],
      filesSync: true,
      idProviderInvoice: idPreinvoiceProvider,
      updatedBy: createdBy,
      invoiceNumberSupplier: invoiceNumber,
    };

    await axios.post(`${URL_BASE}documentos/factura/sincronizar-archivos`, {
      uuid,
      pdf: queuUpload[0].urlDownload,
      xml: queuUpload[1].urlDownload,
      idProviderInvoice: idPreinvoiceProvider,
      updatedBy: createdBy,
      idPreinvoice,
      invoiceNumberSupplier: invoiceNumber,
    });

    return infoFilesUploaded;
  } catch (error) {
    console.log(error);

    throw {
      filesSync: false,
      codeError: "A1",
      message: `El Proceso de facturación ha sido completado, sin embargo, no fue posible realizar la sincronización de datos entre “Praxia” y el sistema de facturación.
        En breve el equipo de soporte realizará el proceso de sincronización y usted recibirá un mail de confirmación.`,
      errorDetails: error,
      step: 2,
    };
  }
}

/**
 * Request the revision of preinvoice if validations are not ok
 * @param {number} partialities - Number of partialities requested
 * @param {number} tc - TC requested for the exchange currency
 * @param {number} idInvoice - Id of the preinvoice
 * @param {boolean} requiresExchange - Indicate if the user requested change currency of the document (Cambio de moneda)
 * @returns {Promise<boolean>} True if revision was requested
 */
export const requestRevisionInvoice = async (
  partialities,
  tc,
  idInvoice,
  requiresExchange = false
) => {
  try {
    await axios.put(`${URL_BASE}documentos/prefactura/revision`, {
      id: idInvoice,
      partialities: partialities,
      tc: tc,
      requiresExchange: requiresExchange,
    });

    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

/**
 *
 * @param {number|string} idPreinvoice - Id the preinvoice
 * @param {number|string} idQuote - Id of the quote related with the preinvoice
 * @param {string} executive - Name of the executive who attempted the action
 * @param {LogInsertion} infoToCreateLog - Information to create an error log
 */
export const reverseCxC = async (
  idPreinvoice,
  idQuote,
  executive,
  infoToCreateLog
) => {
  try {
    // console.log(idPreinvoice, idQuote, executive, infoToCreateLog);

    await Promise.all([
      axios.post(`${URL_BASE}errores/factura/crear/paso1`, {
        idPreinvoice,
        idQuote,
        executive,
        log: infoToCreateLog,
      }),
      createLog({
        ...infoToCreateLog,
        provider: 1,
        htmlError: createPrettyJsonHtml(infoToCreateLog),
      }),
    ]);
  } catch (error) {
    Error(() => {}, "Error A1: Habla con los administradores del sistema");
  }
};

/**
 * Update cfdi, pay method and form pay of the invoice
 * @param {PayInfo} payMethodsInfo - Information of the method pays
 */
export async function updatePayMethodsInvoivce(payMethodsInfo) {
  try {
    await axios.put(
      `${URL_BASE}documentos/factura/metodos-pago`,
      payMethodsInfo,
      {
        withCredentials: true,
      }
    );
  } catch (error) {
    console.log(error);

    throw {
      filesSync: false,
      codeError: "A1",
      message:
        "Error A1. Factura creada pero no fue posible sincronizar la informacion, paso 2/2 fallido. Contacte a soporte tecnico",
      errorDetails: error,
      step: 2,
    };
  }
}

/**
 * Update the invoice
 * @param {import("types/typedef/documents").DocumentI} document - Information of the document updated
 * @param {import("types/typedef/customHooks/useComments").CommentsI} comments - Information of the comments
 * @param {{
 *  id:number,
 *  fullName:string
 * }} executive - Info of the executive
 * @returns {boolean} True if invoice was updated successfully
 */
export async function updateInvoice(document, comments, executive) {
  try {
    await axios.put(`${URL_BASE}documentos/prefactura`, {
      document,
      comments,
      executive,
    });

    return true;
  } catch (error) {
    const { response } = error;
    const { data, status, statusText } = response;

    Error(() => {}, `Error: ${data.errorCode}. ${data.message}`);
    return false;
  }
}

/**
 * Add an invoice into system
 * @param {import("types/typedef/invoice").DtoAddInvoice} dto - Dto in order to create an invoice against the system
 * @returns {boolean} True if query was success
 */
export async function addInvoice(dto) {
  try {
    await axios.post(`${URL_BASE}documentos/agregar/documento/pedido`, dto, {
      withCredentials: true,
    });

    return true;
  } catch (errro) {
    return false;
  }
}

/**
 * Upadte an invoice into system
 * @param {import("types/typedef/invoice").DtoAddInvoice} dto - Dto in order to create an invoice against the system
 * @returns {boolean} True if query was success
 */
export async function updateInvoiceV2(dto) {
  try {
    await axios.put(`${URL_BASE}documentos/actualizar/pedido`, dto, {
      withCredentials: true,
    });

    return true;
  } catch (error) {
    return false;
  }
}

/**
 * Get the invoices for administration
 * @param {import("types/typedef/preinvoice").GetInvoicesParamsI} queryParams - Params in order to search the invoices
 * @param {import("components/general/Forms/Selects/StatusInvoice/types").FROM_INVOICE} from - Module in order to fetch the correct invoices
 * @returns {Promise<import("types/typedef/preinvoice").InvoicesPaginatedI>} Information of the invoices got from the api
 */
export async function getInvoicesPaginated(queryParams, from) {
  try {
    const { customer, status, startDate, endDate, search, page, accounting } =
      queryParams;

    const queryAccountingStatus =
      accounting === null ? "" : `&accounting=${accounting}`;

    const customerQueryParam =
      customer === null ? "" : `&customerId=${customer}`;

    const statusQueryParam =
      status === -1
        ? ""
        : from === "pedidos"
        ? `&statusId=${status}`
        : `&status=${status}`;

    const searchQueryParam =
      search === null || search === 0 || `${search}` === "0"
        ? ""
        : `&search=${search}`;
    if (from === "pedidos") {
      const { data } = await axios.get(
        `${URL_BASE}documentos/obtener/tabla/pedidos?beginDate=${startDate}${customerQueryParam}${statusQueryParam}&endDate=${endDate}&pageRequested=${page}${queryAccountingStatus}${searchQueryParam}`,
        {
          withCredentials: true,
        }
      );

      return {
        documents: data.data.map((item) => ({
          ...item,
          facturar: item.facturar.formated,
          isEditable: item.status.id === 4,
          emitida:
            item.emited === null
              ? ""
              : parseDateToText(getDateFromUtc(item.emited)),
        })),
        actualPage: data.pagination.actualPage,
        pages: data.pagination.pages,
      };
    } else {
      const { data } = await axios.get(
        `${URL_BASE}administracion/facturas-emitidas/obtener/tabla?beginDate=${startDate}${customerQueryParam}${statusQueryParam}&endDate=${endDate}&pageRequested=${page}${queryAccountingStatus}${searchQueryParam}`,
        {
          withCredentials: true,
        }
      );

      return {
        documents: data.data.map((item) => ({
          ...item,
          facturar: item.facturar.formated,
          isEditable: item.status.id === 4,
          expirationDateFl: parseDateToText(`${item.facturar.yyyymmdd}:`),
          emitida:
            item.emited === null
              ? ""
              : parseDateToText(getDateFromUtc(item.emited)),
        })),
        actualPage: data.pagination.actualPage,
        pages: data.pagination.pages,
      };
    }
  } catch (error) {
    return {
      documents: [],
      actualPage: 1,
      pages: 0,
    };
  }
}

/**
 * Parse the invoices in order to display it on the library js-smart-table
 * @param {import("customHooks/useAdministrationInvoice/types").InvoiceI[]} invoices
 */
export const parseInvoicesPaginates = (invoices) =>
  invoices.map((invoice) => ({
    ...invoice,
    total: invoice.total.texto,
    registro: invoice.registro.formated,
    // facturar: invoice.facturar.formated,
  }));

/**
 * Information in order to attempt creation invoice against sat
 * @param {number} id - Id of the invoice to get his information in order to create the invoice against sat
 * @returns {import("types/typedef/invoice").DtoRequestAttemptInvoice}
 */
export async function getInvoiceAttemptData(id) {
  try {
    const { data } = await axios.get(`${URL_BASE}facturacion/${id}`, {
      withCredentials: true,
    });

    return data;
  } catch (error) {
    // console.log(error.)
    const {
      request,
      response: { data },
    } = error;

    console.log(data);

    return data;
  }
}

/**
 * Add a new invoice into system
 * @param {import("../../../../server/types/Invoice/dto").DtoAttemptInvoiceSat} dto - dto in order to create the invoice
 */
export async function createInvoice(dto) {
  try {
    const { data } = await axios.post(
      `${URL_BASE}documentos/facturar/pedido`,
      dto,
      {
        withCredentials: true,
      }
    );

    Success(() => {}, data.message);

    return true;
  } catch (error) {
    HtmlAlert(
      {
        html: `<code>${JSON.stringify(
          error.response.data.message,
          null,
          "\t"
        )}</code>`,
        title: "Error al facturar",
      },
      "error"
    );

    return false;
  }
}

/**
 * Send the reply of the invoice authorization
 * @param {import("../../../../types/invoiceAuthorizations").DtoUpdateAuthorizationI} dto
 * @returns {Promise<boolean>} True if repply was success
 */
export async function repplyInvoiceAuthorization(dto) {
  try {
    await axios.put(
      `${URL_BASE}documentos/responder/solicitud/autorizacion`,
      { authResponse: dto },
      {
        withCredentials: true,
      }
    );
    return true;
  } catch (error) {
    return false;
  }
}

/**
 * @param {string} search - Search to use on the catalogue
 * @returns {Promise<import("../../../../types/facturama").DtoClaveProductoServicio[]>} Items founded on the search
 */
export async function searchClaveProductoServicio(search = "") {
  try {
    const { data } = await axios.get(
      `${URL_BASE}facturacion/catalogo/productos-y-servicios?busqueda=${search}`,
      {
        withCredentials: true,
      }
    );

    return data;
  } catch (error) {
    DisplayError(error);
    return [];
  }
}

/**
 * Get the UM founded with the param
 * @param {string} search - Search to use for the query
 * @returns {Promise<import("../../../../types/facturama").DtoUmSat[]>} 'Unidades de medida' founded by the SAT
 */
export async function searchUmSat(search = "") {
  try {
    const { data } = await axios.get(
      `${URL_BASE}facturacion/catalogo/um?busqueda=${search}`,
      {
        withCredentials: true,
      }
    );

    return data;
  } catch (error) {
    DisplayError(error);
    return [];
  }
}

/**
 * Update the sat code of the uen on the catalogue
 * @param {string} code - Sat code
 * @param {string} description - Description of that sat code
 * @param {number} id - Id of the UEN to update
 * @returns {Promise<boolean>} If true, uen was correctly updated
 */
export async function updateSatCode(code, description, id) {
  /**
   * DTO in order to update
   * @type {import("../../../../types/catalogue").DtoUpdateCatalogue}
   */
  const dto = {
    description,
    satCode: code,
    id,
  };

  try {
    const { data } = await axios.put(`${URL_BASE}catalogo/satcode`, dto, {
      withCredentials: true,
    });

    Success(() => {}, data.message);

    return true;
  } catch (error) {
    DisplayError(error);
    return false;
  }
}

/**
 * Update the UM sat on the UEN catalogue
 * @param {number} id - ID of the uen to update
 * @param {string} description - Description of the UM
 * @param {string} um - UM sat to use
 * @returns {Promise<boolean>} True if was updated correctly
 */
export async function updateUmSat(id, description, um) {
  /**
   * Information to send in order to update the um sat on the UEN
   * @type {import("../../../../types/catalogue").DtoUpdateUmSat}
   */
  const dto = {
    id,
    description,
    satUM: um,
  };

  try {
    const { data } = await axios.put(`${URL_BASE}catalogo/satum`, dto, {
      withCredentials: true,
    });

    Success(() => {}, data.message);
    return true;
  } catch (error) {
    DisplayError(error);
    return false;
  }
}

/**
 * Cancel a preinvoice
 * @param {number} id - Id of the document
 * @returns {Promise<boolean>}
 */
export async function cancelPreinvoice(id) {
  try {
    const res = await fetch(`${URL_BASE}documentos/pedido/${id}`, {
      credentials: "include",
      method: "DELETE",
    });

    const data = await res.json();

    if (res.ok && isValidHttpResCode(res.status)) {
      Success(() => {}, data.message);
      return true;
    } else {
      Error(() => {}, data.message);
      return false;
    }
  } catch (error) {
    console.log("Fallo la cancelacion del pedido");
    console.log(error);
    // Error(() => {}, data.message);
    return false;
  }
}

/**
 * Group the taxes by group
 * @param {import("../../../../server/types/administration").XmlImpuestos[]} taxes - Taxes readed from the XML file
 * @returns {import("./typesInvoice").GroupedTaxes}
 */
export function groupTaxes(taxes = [], areTransferredTaxes = false) {
  const agrupation = areTransferredTaxes
    ? {
        iva: [],
        ieps: [],
      }
    : {
        isr: [],
        iva: [],
        ieps: [],
      };

  // 001 - ISR
  // 002 - IVA
  // 003 - IEPS

  return taxes.reduce((indexed, tax) => {
    if (tax.Impuesto === "001")
      return {
        ...indexed,
        isr: [...indexed.isr, tax],
      };

    if (tax.Impuesto === "002")
      return {
        ...indexed,
        iva: [...indexed.iva, tax],
      };

    return {
      ...indexed,
      ieps: [...indexed.ieps, tax],
    };
  }, agrupation);
}

/**
 * Get the invoices for administration
 * @param {import("types/typedef/preinvoice").GetInvoicesParamsI} queryParams - Params in order to search the invoices
 * @returns {import("types/typedef/preinvoice").InvoicesPaginatedI} Information of the invoices got from the api
 */
export async function getInvoicesPaginatedV2(queryParams) {
  try {
    const { customer, status, startDate, endDate, search, page } = queryParams;

    const customerQueryParam =
      customer === null ? "" : `&customerId=${customer}`;

    const statusQueryParam = status === -1 ? "" : `&status=${status}`;

    const searchQueryParam = search === null ? "" : `&search=${search}`;

    const { data } = await axios.get(
      `${URL_BASE}administracion/facturas-emitidas/obtener/tabla?beginDate=${startDate}${customerQueryParam}${statusQueryParam}&endDate=${endDate}&pageRequested=${page}${searchQueryParam}`,
      {
        withCredentials: true,
      }
    );

    return {
      documents: data.data.map((item) => ({
        ...item,
        facturar: item.facturar.formated,
        emitida:
          item.emited === null
            ? ""
            : new Intl.DateTimeFormat("es-MX", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
                hour: "numeric",
                minute: "numeric",
                second: "numeric",
              }).format(getDateFromUtc(item.emited)),
      })),
      actualPage: data.pagination.actualPage,
      pages: data.pagination.pages,
    };
  } catch (error) {
    return {
      documents: [],
      actualPage: 0,
      pages: 0,
    };
  }
}

/**
 * Add uen into system
 * @param {import("../../../../server/types/UEN/uen").AddUenI} uen - DTO
 * @returns {Promise<boolean>}
 */
export async function addUen(uen) {
  try {
    const response = await fetch(`${URL_BASE}uen`, {
      credentials: "include",
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ uenData: uen }),
    });

    const data = await response.json();

    if (response.ok && isValidHttpResCode(response.status)) {
      Success(() => {}, data.message);
      return true;
    }

    Error(() => {}, data.message);
    return false;
  } catch (error) {
    return false;
  }
}

/**
 * Add uen into system
 * @param {import("../../../../server/types/UEN/uen").UpdateUenI} uen - DTO
 * @returns {Promise<boolean>}
 */
export async function updateUen(uen) {
  try {
    const response = await fetch(`${URL_BASE}uen`, {
      credentials: "include",
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ uenData: uen }),
    });

    const data = await response.json();

    if (response.ok && isValidHttpResCode(response.status)) {
      Success(() => {}, data.message);
      return true;
    }

    Error(() => {}, data.message);
    return false;
  } catch (error) {
    return false;
  }
}

/**
 * Download the excel report for the `Pedidos`or `Facturas emitidas`
 * @param {import("components/general/Forms/Selects/StatusInvoice/types").FROM_INVOICE} from - Module in order to fetch the correct excel
 * @param {import("types/typedef/preinvoice").GetInvoicesParamsI} queryParams - Params
 * @returns {Promise<Blob|undefined>}
 */
export async function getReportInvoiceExcel(from, queryParams) {
  const urlToUse =
    from === "facturas_emitidas"
      ? `administracion/facturas-emitidas/reporte`
      : "";

  try {
    const beginDate = `?beginDate=${queryParams.startDate}`;
    const endDate = `&endDate=${queryParams.endDate}`;
    const status =
      queryParams.status === -1 ? "" : `&status=${queryParams.status}`;
    const search =
      queryParams.search === null ? "" : `&search=${queryParams.search}`;

    const response = await fetch(
      `${URL_BASE}${urlToUse}${beginDate}${endDate}${status}${search}`,
      {
        credentials: "include",
        method: "GET",
      }
    );

    if (response.ok && isValidHttpResCode(response.status)) {
      const excel = await response.blob();
      return excel;
    }

    return undefined;
  } catch (error) {
    return undefined;
  }
}

/**
 * Get the options for the combo
 * @param {number} idCustomer
 * @returns {Promise<import("../../../../server/types/facturama.d.ts").CorporativeInvoiceItem[]>}
 */
export async function getCorporativeComboInvoice(idCustomer = null) {
  try {
    const response = await fetch(
      `${URL_BASE}facturacion/corporativo/${idCustomer}`,
      {
        credentials: "include",
        method: "GET",
      }
    );

    if (!response.ok || !isValidHttpResCode(response.status)) return [];

    /**
     * @type {import("../../../../server/types/facturama.d.ts").CorporativeInvoiceItem[]}
     */
    const data = await response.json();

    return data;
  } catch (error) {
    return [];
  }
}

/**
 * Get the invoices to be downloaded as massive
 * @param {Date|string} from - From date
 * @param {Date|string} to - To date
 * @param {import("structure/MassiveInvoiceDownload/useMassiveInvoiceDownload/types").MassiveDownloadItem} [type="facturasE"] - Type of invoice to download
 */
export async function massiveInvoicesOverview(from, to, type = "facturasE") {
  const queryParam = arrayToQueryParam([
    typeof from === "string"
      ? `beginDate=${from}`
      : `beginDate=${dateToDbFormat(from)}`,
    typeof to === "string" ? `endDate=${to}` : `endDate=${dateToDbFormat(to)}`,
    `type=${type}`,
  ]);

  try {
    /**
     * @type {import("axios").AxiosResponse<import("./typesInvoice").MassiveInvoiceI[]>}
     */
    const res = await axios.get(`${URL_BASE}facturas/multiples${queryParam}`, {
      withCredentials: true,
    });

    return res.data;
  } catch (error) {
    return [];
  }
}
