import ButtonV2 from "components/individual/ButtonsV2/Button";
import Icon from "components/individual/HeaderIcon";
import { Search } from "components/individual/Search";
import React, { createContext, useCallback } from "react";
import { Label } from "structure/Document";
import ui from "./styles.module.scss";
import BreadCrumV2 from "components/individual/breadCrums/BreadCrumV2";
import useProyects from "customHooks/useProyects";
import TableProyects from "./TableProyects";
import Options from "./Options";
import { parseToFolio } from "helpers/documents";
import PositionsOverview from "structure/PositionsOverview";
import ProyectStatus from "components/general/ProyectStatus";
import RedirectBtn from "components/individual/RedirectBtn";
import InformativeTooltip from "components/individual/InfoTooltip";
import usePermissions from "customHooks/usePermissions";

/**
 * @type {import("react").Context<import("customHooks/useProyects/types").ReturnUseProyects|undefined>}
 */
export const ContextProyects = createContext(undefined);

export default function Proyects() {
  const hook = useProyects();

  usePermissions('5925c696-ba6a-4a78-8a76-3b91053f6af9');
  
  // Memorize the component since the library re-renders the inner expandable component for each change, not doing this will infinitely re-render the component
  const expandableRowsComponent = useCallback(
    ({ data }) => (
      <PositionsOverview
        idProyect={data.id}
        onChange={(records) => hook.setPosition(records)}
      />
    ),
    // []);
    [hook.selectedProyect,hook.projects,hook.isCheckedPosition,hook.refetchPositions]);

  return (
    <ContextProyects.Provider value={hook}>
      <div className={ui.proyects}>
        <BreadCrumV2 path={hook.breadcrum.current} />

        <form
          className={ui.formProyects}
          onSubmit={(e) => {
            e.preventDefault();
            hook.refetchTable();
          }}
        >
          <div className="d-none">
          <ProyectStatus
            className="d-none"
            onChange={(e) => hook.setStatus(e.target.value)}
            value={hook.status}
          >
            <option value="" disabled>
              -- SELECCIONA --
            </option>
          </ProyectStatus>
          </div>


          <div>
            <Label maxLength={1000}>Texto libre <InformativeTooltip>
              <p>La búsqueda se realiza por: </p>
              <ul>
                <li>- Solped</li>
                <li>- Comprador</li>
                <li>- No. proyecto</li>
                <li>- Cliente (Razón social)</li>
                <li>- RFQ</li>
              </ul>
              </InformativeTooltip></Label>
            <Search
              hideSearchIcon={true}
              placeholder="Escribe aquí"
              onChange={(data) => hook.setSearchField(data)}
              onDelete={hook.setSearchField}
              value={hook.searchField}
            />
          </div>

          <ButtonV2 type="submit">
            <span>Buscar</span>
            <Icon icon="search" />
          </ButtonV2>

          <RedirectBtn link="/proyectos/formulario/0">
            <span>Agregar</span>
            <Icon icon="plus" />
          </RedirectBtn>

          <ButtonV2 type="button" onClick={hook.handleDownlaodExcel} disabled={hook.excelStatus==='loading'}>
            <span>Excel</span>
            <Icon icon="excel" />
          </ButtonV2>
        </form>

        <Options />

        <TableProyects
          data={hook.projects.data}
          expandableRowsComponent={expandableRowsComponent}
          expandableRows={true}
          progressPending={hook.isLoading}
          selectableRowSelected={(item) =>
            item.id === hook.selectedProyect?.id &&
            hook.selectedPosition.length <= 0
          }
          onRowClicked={(proyect, e) => hook.setProyect(proyect)}
          columns={[
            {
              name: "No. proyecto",
              selector: (data) => parseToFolio(data.id),
              maxWidth: "15%",
            },
            {
              name: "Solped",
              selector: (data) =>
                data.solped === null ? "No disponible" : data.solped,
              maxWidth: "15%",
            },
            {
              name: "Comprador",
              selector: (data) => data.buyer,
              maxWidth: "30%",
            },
            {
              name: "Cliente",
              selector: (data) =>
                data.client === null ? "No disponible" : data.client,
              maxWidth: "40%",
            }
            // {
            //   name: "Estatus proyecto",
            //   maxWidth: "10%",
            //   selector: (data) =>
            //     hook.indexedStatusProyect.current[data.statusProyect] ||
            //     "No disponible",
            // },
          ]}
        />
      </div>
    </ContextProyects.Provider>
  );
}
