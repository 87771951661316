import React, { createContext } from "react";
import useIvas from "./useIvas";
import Filter from "./Filter";
import TableIvas from "./Table";
import ui from "./styles.module.scss";
import BreadCrumV2 from "components/individual/breadCrums/BreadCrumV2";
import Details from "components/general/Details";
import Totals from "./Totals";

/**
 * @type {import('./types').ContextIvasReport}
 */
export const IvasContextReport = createContext(undefined);

export default function IVAS() {
  const hook = useIvas();

  return (
    <div className={ui.container}>
      <IvasContextReport.Provider value={hook}>
        <BreadCrumV2 path={hook.breadcrum} />   	  

        <div className={ui.module}>
          <Filter />

          <Details summary="Ingresos" open style={{ maxHeight: "100%" }}>
            <div className={ui.header}>
              <p>MXN</p>
              <p>USD</p>
              <p>Total en pesos</p>
            </div>
            <TableIvas
              customStyles={{
                headRow: hook.styles,
                rows: hook.styles,
              }}
              pagination={false}
              progressPending={hook.isLoading}
              data={hook.ingress}
              columns={hook.columns}
              responsive={false}
              fixedHeaderScrollHeight="100%"
            />

            <Totals type="ingreso" />
          </Details>

          <Details summary="Egresos" open style={{ maxHeight: "100%" }}>
            <div className={ui.header}>
              <p>MXN</p>
              <p>USD</p>
              <p>Total en pesos</p>
            </div>
            <TableIvas
              customStyles={{
                headRow: hook.styles,
                rows: hook.styles,
              }}
              fixedHeaderScrollHeight="100%"
              pagination={false}
              progressPending={hook.isLoading}
              data={hook.egress}
              columns={hook.columns}
              
              responsive={false}
            />
            <Totals type="egreso" />
          </Details>
        </div>
      </IvasContextReport.Provider>
    </div>
  );
}
