import React, { useContext } from "react";
import YearSelect from "components/general/Forms/Selects/Year";
import MonthSelect from "components/general/Forms/Selects/Month";
import { IvasContextReport } from "..";
import ButtonV2 from "components/individual/ButtonsV2/Button";
import Icon from "components/individual/HeaderIcon";
import ui from "./styles.module.scss";
import IvaResult from "../IvaResult";

export default function Filter() {
  const hook = useContext(IvasContextReport);

  return (
    <div className="d-flex align-items-center justify-content-between">
      <div className={ui.filter}>
        <YearSelect
          value={hook.year}
          from={new Date().getUTCFullYear() - 10}
          onChange={hook.setYear}
          to={new Date().getUTCFullYear()}
        />
        <MonthSelect value={hook.month} onChange={hook.setMonth} />

        <ButtonV2 disabled={hook.isLoading} onClick={hook.attemptSearch}>
          <span>Buscar</span>
          <Icon icon="search" />
        </ButtonV2>

        <ButtonV2
          disabled={hook.isDownloadingExcel}
          onClick={()=>hook.attemptDownloadExcel(true)}
        >
          <span>Excel</span>
          <Icon icon="excel" />
        </ButtonV2>
      </div>

      <IvaResult />
    </div>
  );
}
