import { saveAs } from "file-saver";
import { getIvasReport } from "helpers/Apis/Documents";
import { getDateFromUtc, parseDateToText } from "helpers/dates";
import { parseToFolio } from "helpers/documents";
import { mnCurrency } from "helpers/money";
import { useEffect, useRef, useState } from "react";

/**
 * @type {import("./types").StateUseIvasReport}
 */
const INITIAL_STATE = {
  isLoading: true,
  ingress: [],
  egress: [],
  month: new Date().getUTCMonth() + 1,
  year: new Date().getUTCFullYear(),
  refetch: false,
  isDownloadingExcel: false,
  totalIncomes:undefined,
  totalOutcomes:undefined,
  ivaUsed:0
};

/**
 * Hook to handle the ivas report of praxia
 * @returns {import("./types").ReturnUseIvasReport}
 */
export default function useIvas() {
  const [state, setState] = useState(INITIAL_STATE);

  const styles = useRef({
    style: {
      display: "grid",
      gridTemplateColumns: "repeat(14,7.1%)",
      textAlign:"right"
    },
  });

  useEffect(() => {
    (async function () {
      setState((current) => ({
        ...current,
        isLoading: true,
      }));

      const ivas = await getIvasReport(state.year, state.month, "report");

      if (ivas instanceof Blob) return;

      // const tcIvaIncoming = ivas.ivaIncomes.length > 0 ? ivas.ivaIncomes[0].tc : 0;
      // const tcIvaEgresses = ivas.ivaOutcomes.length > 0 ? ivas.ivaOutcomes[0].tc : 0;

      // const tcUsed = tcIvaIncoming > 0 ? tcIvaIncoming : tcIvaEgresses;


      setState((current) => ({
        ...current,
        isLoading: false,
        egress: ivas.ivaOutcomes,
        ingress: ivas.ivaIncomes,
        totalIncomes:ivas.totalIncomes,
        totalOutcomes:ivas.totalOutcomes,
        ivaUsed:0
        
      }));
    })();
  }, [state.refetch]);

  const attemptSearch = () =>
    setState((current) => ({
      ...current,
      refetch: !current.refetch,
    }));

  const attemptDownloadExcel = async (promptSave=true) => {
    setState((current) => ({
      ...current,
      isDownloadingExcel: true,
    }));

    const ivas = await getIvasReport(state.year, state.month, "excel");

    if (ivas instanceof Blob && promptSave===true) {
      saveAs(ivas, `Reporte IVAS.xlsx`);
    }

    setState((current) => ({
      ...current,
      isDownloadingExcel: false,
    }));

    if(ivas instanceof Blob){
      return ivas
    };

    return null
  };

  const setYear = (year) =>
    setState((current) => ({
      ...current,
      year,
    }));

  const setMonth = (month) =>
    setState((current) => ({
      ...current,
      month,
    }));

  return {
    ...state,
    columns: [
      {
        name: "Folio",
        selector: (record) => (record.folio),
        reorder: true,
        sortable: true,
      },
      {
        name: "Fecha",
        reorder: true,
        selector: (record) => parseDateToText(typeof record.date === 'string' ? `${record.date.split("T")[0]}:` : record.date),

        sortable: true,
      },
      {
        name: "Concepto",
        reorder: true,
        selector: (record) =>
          Array.isArray(record.concept)
            ? record.concept.reduce(
                (msg, concept) => `${concept},${msg}`,
                ""
              )
            : "ND",

        sortable: true,
      },
      {
        name: "Descripción cuenta",
        reorder: true,
        selector: (record) => record.accountDescription,

        sortable: true,
      },
      {
        name: "Importe",
        selector: (record) =>
          record.currency !== "MXN" ? "-" : mnCurrency(record.import),

        sortable: true,
      },
      {
        name: "IVA",
        selector: (record) =>
          record.currency !== "MXN" ? "-" : mnCurrency(record.iva),

        sortable: true,
      },
      {
        name: "Total",
        selector: (record) =>
          record.currency !== "MXN" ? "-" : mnCurrency(record.total),

        sortable: true,
      },
      {
        name: "Importe",
        selector: (record) =>
          record.currency !== "USD" ? "-" : mnCurrency(record.import),

        sortable: true,
      },
      {
        name: "IVA",
        selector: (record) =>
          record.currency !== "USD" ? "-" : mnCurrency(record.iva),

        sortable: true,
      },
      {
        name: "Total",
        selector: (record) =>
          record.currency !== "USD" ? "-" : mnCurrency(record.total),

        sortable: true,
      },
      {
        name: "Importe",
        selector: (record) => mnCurrency(record.withTcApplied.import),

        sortable: true,
      },
      {
        name: "IVA",
        selector: (record) => mnCurrency(record.withTcApplied.iva),
      },
      {
        name: "Total",
        selector: (record) => mnCurrency(record.withTcApplied.total),

        sortable: true,
      },
      {
        name:"TC",
        selector:(record)=>mnCurrency(record.tc,4),
        sortable:false
      }
    ],
    setYear,
    setMonth,
    attemptDownloadExcel,
    attemptSearch,
    styles: styles.current,
    breadcrum: [
      {
        route: "/inicio",
        text: "Inicio",
      },
      {
        route: "/v2/bancos",
        text: "Bancos",
      },
      {
        route: "/v2/bancos",
        text: "Reportes",
      },
      {
        route: "/",
        text: "IVA",
      },
    ],
  };
}
