import { RangeDate, From, To , FromB , ToB } from "components/general/RangeDate";
import React, { useContext } from "react";
import { ContextDocuments } from "../..";

export default function Dates() {
  const hook = useContext(ContextDocuments);

  return (
    <RangeDate>
      <FromB
      defaultValue={new Date(hook.from)}
        onChange={(date) => hook.setDate("from", date)}
      />

<ToB
              defaultValue={new Date(hook.to)}
              onChange={(date) => hook.setDate("to", date)}
      />
      {/* <From
        selected={new Date(hook.from)}
        onChange={(date) => hook.setDate("from", date)}
      />

      <To
        selected={new Date(hook.to)}
        onChange={(date) => hook.setDate("to", date)}
      /> */}
    </RangeDate>
  );
}
