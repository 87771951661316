import React, { Fragment, useContext } from "react";
import { ContextInvoiceEmittedV2 } from "..";
import ButtonV2 from "components/individual/ButtonsV2/Button";
import { ModalEmail } from "structure/EmailV2";
import DownloadSatInvoices from "components/general/DownloadSatInvoices";
import { CancelInvoiceButton } from "components/general/Documents/Buttons/CancelInvoiceButton";
import { downloadFeFiles } from "helpers/Apis/associateFilesV2";
import ModalCreateInvoiceV2 from "structure/ModalCreateInvoiceV2";
import { RedirectButton } from "components/individual/buttons/Buttons";
import EmailDocumentsV2 from "components/individual/EmailDocumentsV2";
import { DonwloadPdfV2 } from "components/general/Documents/DownloadPdf/DonwloadPdfV2";
import { CancelPreInvoice } from "components/general/CancelPreInvoice";
import { parseToFolio } from "helpers/documents";

export default function Options() {
  const hook = useContext(ContextInvoiceEmittedV2);

  if (hook.from === "facturas_emitidas" && hook.invoice)
    return (
      <Fragment>
        <ButtonV2
          disabled={hook.invoice === null}
          onClick={hook.redirectToViewDocument}
        >
          <span>Ver documento</span>
        </ButtonV2>

        <ButtonV2 onClick={hook.redirectToAssociatedFiles}>
          <span>Asociar archivos</span>
        </ButtonV2>

        <ButtonV2 onClick={hook.redirectToDo}>
          <span>To Do</span>
        </ButtonV2>

        <ModalEmail
          idModule={hook.invoice.id}
          files={() => downloadFeFiles(hook.invoice.xml, hook.invoice.pdf)}
          module="facturaEmitida"
          idDirectory={hook.invoice.customer.id}
        >
          <ButtonV2>
            <span>Enviar correo</span>
          </ButtonV2>
        </ModalEmail>

        <DownloadSatInvoices
          idPdf={hook.invoice.pdf}
          id={hook.invoice.id}
          idXml={hook.invoice.xml}
        />

        <CancelInvoiceButton
          uuid={hook.invoice.uuid}
          onCancelled={() => hook.attemptSearch()}
        />
      </Fragment>
    );

  if (hook.from === "pedidos" && hook.preinvoice.preinvoice)
    return (
      <Fragment>
        <ButtonV2
          disabled={hook.preinvoice.preinvoice === null}
          onClick={hook.redirectToViewDocument}
        >
          <span>Ver documento</span>
        </ButtonV2>

        <ModalCreateInvoiceV2
          idOrder={hook.preinvoice.preinvoice.id}
          idStatus={hook.preinvoice.preinvoice.status.id}
          evaluatePermission={false}
          onCreated={() => hook.attemptSearch()}
        />

        <ButtonV2 onClick={hook.redirectToAssociatedFiles}>
          <span>Asociar archivos</span>
        </ButtonV2>

        <ButtonV2 onClick={hook.redirectToDo}>
          <span>To Do</span>
        </ButtonV2>

        <RedirectButton
          text="Editar"
          link={`../documentos/pedido/editar?idDocumento=${hook.preinvoice.preinvoice.id}&modulo=pedidos&idCliente=${hook.preinvoice.preinvoice.customer.id}`}
        />

        <EmailDocumentsV2 idDocument={hook.preinvoice.preinvoice.idPreinvoice}>
          <ButtonV2>
            <span>Enviar correo</span>
          </ButtonV2>
        </EmailDocumentsV2>

        <DonwloadPdfV2
          id={hook.preinvoice.preinvoice.idPreinvoice}
          fileName={`Pedido-${parseToFolio(+hook.preinvoice.preinvoice.numeroDocumento)}`}
        />

        <CancelPreInvoice
          idPreinvoice={hook.preinvoice.preinvoice.id}
          onCancelled={() => hook.attemptSearch()}
        />
      </Fragment>
    );
}
