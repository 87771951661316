import React, { Fragment } from "react";
import CardDocRelated, { EmptyCardMoreInfo } from "../CardDocRelated";
import { ContextDocsRelated } from "..";
import { useContext, useRef } from "react";
import { getDateFromUtc, parseDateToText } from "helpers/dates";

export default function FacturaRecibida() {
  const hook = useContext(ContextDocsRelated);

  const key = useRef(`${window.crypto.randomUUID()}`);

  const frs = hook.popUpInfo.fr;

  if (frs.length <= 0) return <EmptyCardMoreInfo label="Facturas recibida" />;

  return (
    <Fragment>
      {frs.map((fr, i) => (
        <CardDocRelated
          key={`${key.current}-${i}`}
          id={fr.id}
          folio={fr.folio}
          endDate={parseDateToText(getDateFromUtc(fr.expirationDate))}
          customer={fr.provider.socialReason}
          startDate={parseDateToText(getDateFromUtc(fr.emitedDate))}
          email={fr.provider.contact.email}
          phone={fr.provider.contact.phone.parsed}
          importe={fr.importe.text}
          iva={fr.iva.text}
          total={fr.total.text}
          currency={fr.currency}
          label="Factura recibida"
          concept={fr.concepts.length > 0 ? fr.concepts[0]?.description : "ND"}
          status={fr.status.description}
        />
      ))}
    </Fragment>
  );
}
