import React, { createContext } from "react";
import useInvoices from "customHooks/useInoivces";
import { TableToUse } from "./Table";
import ui from "./styles.module.scss";
import ButtonV2 from "components/individual/ButtonsV2/Button";
import BreadCrumV2 from "components/individual/breadCrums/BreadCrumV2";
import SocialReason from "./SocialReason";
import Dates from "./Dates";
import Status from "./Status";
import Folio from "./Folio";
import SearchButton from "./SearchButton";
import Options from "./Options";

/**
 * @type {import("react").Context<import("customHooks/useInoivces/types").ReturnUseInvoices|null>}
 */
export const ContextInvoiceEmittedV2 = createContext(null);

/**
 * Render the invoices and preinvoices of the system
 * @param {import("./types").PropsInvoiceEmittedV2} props - Props
 */
export default function InvoiceEmittedV2({ from = "facturas_emitidas" }) {
  const hook = useInvoices(from);

  return (
    <ContextInvoiceEmittedV2.Provider value={hook}>
      <div className={ui.container}>
        <BreadCrumV2 path={hook.breadcrum.current[from]} />

        <div className={`${ui.container} mx-5`}>
          <form onSubmit={hook.attemptSearch} className={ui.filters}>
            <div className={`${ui.filterItem} ${ui.leftFilter}`}>
              <SocialReason />
              <Dates />
              <Status />
              <Folio />
              <SearchButton />

              {from==="facturas_emitidas" && <ButtonV2 type="button" onClick={hook.downloadReport}>
                <span>Excel</span>
              </ButtonV2>}
              
            </div>

            <div className={ui.filterItem}>
              <ButtonV2 onClick={hook.redirectToCreatePreinvoice} type="button">
                <span>Agregar</span>
              </ButtonV2>
            </div>
          </form>

          <div className={ui.options}>
            <Options />
          </div>

          <TableToUse />
        </div>
      </div>
    </ContextInvoiceEmittedV2.Provider>
  );
}
