import React, { useRef } from "react";
import ui from "./styles.module.scss";
import FormPersonalData, {
  Name,
  Email,
  Birthday,
} from "structure/FormPersonalData";
import ButtonV2 from "components/individual/ButtonsV2/Button";
import Icon from "components/individual/HeaderIcon";
import { profileUpdateSchema } from "Schemas/Contact/contact";
import useInvoices from "customHooks/useInoivces";
import { Search } from "components/individual/Search";
import { Label } from "structure/Document";
import { RangeDate, FromB, ToB } from "components/general/RangeDate";
import StatusInvoice from "components/general/Forms/Selects/StatusInvoice";
import { noDecimals } from "helpers/money";
import InvoiceEmittedV2 from "pages/Administration/InvoiceEmittedV2";

export default function Test() {
  // const hook = useInvoices("pedidos");
// 
  return <InvoiceEmittedV2 from="pedidos"/>


  return (
    <>
      {/* <div className="d-flex">
        <div>
          <Label>Razon social</Label>
          <Search
            hideSearchIcon={true}
            onChange={(value) => hook.setSocialReason(value)}
            placeholder="Razón social, nombre corto"
            onDelete={() => hook.setSocialReason("")}
          />
        </div>

        <div>
          <RangeDate>
            <FromB
              defaultValue={new Date(hook.startDate)}
              onChange={(date) =>
                hook.setRangeDates(date, new Date(hook.endDate))
              }
            />

            <ToB
              defaultValue={new Date(hook.endDate)}
              onChange={(date) =>
                hook.setRangeDates(new Date(hook.startDate), date)
              }
            />
          </RangeDate>
        </div>

        <div>
          <Label>Estatus</Label>
          <StatusInvoice
            from={"facturas_emitidas"}
            onChange={(option) => hook.setStatus(option.state)}
            includeTodos={true}
            value={hook.status}
            includeNotCreatedAgainstSat={false}
          />
        </div>

        <div>
          <Label>No. factura</Label>
          <Search
            type="number"
            value={hook.folio}
            hideSearchIcon={true}
            placeholder="Escribe aquí"
            onDelete={() => hook.setFolio("")}
            onChange={(data) => hook.setFolio(`${noDecimals(data)}`)}
          />
        </div>
      </div> */}
    </>
  );
  // return (
  //   <FormPersonalData
  //     onSuccessSubmit={(data) => console.log(data)}
  //     resolver={profileUpdateSchema}
  //   >
  //     <Name name="firstName" maxLength={30} required />
  //     <Name
  //       name="middleName"
  //       maxLength={30}
  //       required
  //       placeholder="Segundo nombre"
  //     />
  //     <Name
  //       name="lastName1"
  //       maxLength={30}
  //       required
  //       placeholder="Apellido paterno"
  //     />
  //     <Name
  //       name="lastName2"
  //       maxLength={30}
  //       required
  //       placeholder="Apellido materno"
  //     />
  //     <Email name="email" maxLength={50} required />

  //     <Birthday name="birthday" required />

  //     <ButtonV2>
  //       <span>Guardar</span>
  //       <Icon icon="save" />
  //     </ButtonV2>
  //   </FormPersonalData>
  // );
}
