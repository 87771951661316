import React, { useContext } from "react";
import { Label } from "structure/Document";
import StatusInvoice from "components/general/Forms/Selects/StatusInvoice";
import ui from "../styles.module.scss";
import { ContextInvoiceEmittedV2 } from "..";

export default function Status() {
  const hook = useContext(ContextInvoiceEmittedV2);

  const value = {
    facturas_emitidas:hook.status,
    pedidos:hook.preinvoice.status
  }

  return (
    <div className={ui.status}>
      <Label>Estatus</Label>
      <StatusInvoice
        from={hook.from}
        onChange={(option) => hook.setStatus(option.state)}
        includeTodos={true}
        value={value[hook.from]}
        includeNotCreatedAgainstSat={false}
      />
    </div>
  );
}
