import React from "react";
import { useContext } from "react";
import { ContextCxpReport } from "..";
import { RangeDate as Range, From, To , FromB , ToB } from "components/general/RangeDate";

export default function RangeDate() {
  const hook = useContext(ContextCxpReport);

  return (
    <Range>
      <FromB
      defaultValue={hook.from}
      onChange={(date) => hook.updateBeginDate(date)}
      />
      <ToB
      defaultValue={hook.to}
      onChange={(date) => hook.updateEndDate(date)}
      />
      {/* <From
        required={!hook.isValidFrom()}
        selected={hook.from}
        isClearable={true}
        onChange={(date) => hook.updateBeginDate(date)}
      />
      <To
        required={!hook.isValidTo()}
        selected={hook.to}
        isClearable={true}
        maxDate={new Date()}
        onChange={(date) => hook.updateEndDate(date)}
      /> */}
    </Range>
  );
}
