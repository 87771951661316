import React, { createContext } from "react";
import style from "./styles.module.scss";
import UenMoreInfo from "./UEN";

/**
 *
 * @type {import("react").Context<import("./types").PropsCardDocRelatedI>}
 */
export const ContextMoreInfoDocItem = createContext(undefined);

/**
 * Render the information of docs related
 * @param {import("./types").PropsCardDocRelatedI} props - Props
 * @returns {JSX.Element}
 */
export default function CardDocRelated(props) {
  const {
    endDate,
    folio,
    id,
    customer,
    startDate,
    email,
    phone,
    importe,
    iva,
    total,
    currency,
    label = "",
    concept = null,
    clave = null,
    status = 'No disponible',
    uen = [],
  } = props;

  if (id === null) return <div className={style.empty}>Sin {label}</div>;

  return (
    <ContextMoreInfoDocItem.Provider value={props}>
      <div className={style.card}>
        <div className={style.header}>
          <div>
            <h2>
              {label} <span className={style.folio}>{folio}</span>
            </h2>
            <p>
              <span>Registro</span>
              <span>{startDate}</span>
              <span>Fin</span>
              <span>{endDate}</span>
              <span>Estatus</span>
              <span>{status}</span>
            </p>
          </div>

          <p className={style.currency}>
            <p>
              <img
                src={
                  currency === "MXN"
                    ? "https://upload.wikimedia.org/wikipedia/commons/thumb/f/fc/Flag_of_Mexico.svg/980px-Flag_of_Mexico.svg.png"
                    : "https://upload.wikimedia.org/wikipedia/en/thumb/a/a4/Flag_of_the_United_States.svg/1200px-Flag_of_the_United_States.svg.png"
                }
                alt="currency_document"
              />
              {currency}
            </p>

            <p>{concept}</p>
          </p>
        </div>

        <h4>{customer}</h4>

        <div className={style.details}>
          <div>
            <p>{customer}</p>
            <p>{email}</p>
            <p>
              Teléfono: <span>{phone === "" ? "ND" : phone}</span>
            </p>

            {typeof clave === "string" ? <p>Clave: {clave}</p> : <p>Clave: ND</p>}
          </div>

          <div className={style.amounts}>
            <p>
              Importe <span>{importe}</span>
            </p>
            <p>
              IVA <span>{iva}</span>
            </p>
            <p>
              Total <span>{total}</span>
            </p>
          </div>
        </div>

        <UenMoreInfo />
      </div>
    </ContextMoreInfoDocItem.Provider>
  );
}

export function EmptyCardMoreInfo({ label = "documento" }) {
  return <div className={style.empty}>Sin {label}</div>;
}
