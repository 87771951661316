import React from "react";
import CardDocRelated, { EmptyCardMoreInfo } from "../CardDocRelated";
import { ContextDocsRelated } from "..";
import { useContext } from "react";
import { parseDateToText } from "helpers/dates";

export default function Pedido() {
  const hook = useContext(ContextDocsRelated);

  const pedido = hook.popUpInfo[2];

  if (pedido === undefined || pedido === null)
    return <EmptyCardMoreInfo label="pedido" />;

  return (
    <CardDocRelated
      id={pedido.id}
      folio={pedido.docNumber}
      endDate={parseDateToText(`${pedido.expiration.yyyymmdd}:`)}
      customer={pedido.customer.commercialName}
      startDate={parseDateToText(`${pedido.startDate.yyyymmdd}:`)}
      email={pedido.customer.email}
      phone={pedido.customer.phone}
      importe={pedido.amount.subtotal.text}
      iva={pedido.amount.iva.text}
      total={pedido.amount.total.text}
      currency={pedido.currency.code}
      label="Pedido"
      concept={pedido.concept?.description}
      status={pedido.status.description}
    />
  );
}
