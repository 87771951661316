import { RangeDate, From, To , FromB , ToB } from "components/general/RangeDate";
import React, { useContext } from "react";
import { ContextContract } from "structure/Contract/List";

export default function RangeInput() {
  const { endDate, startDate, updateRangeDates } = useContext(ContextContract);

  return (
    <RangeDate>
      <FromB
        defaultValue={startDate}
        onChange={(date) => updateRangeDates([date, endDate])}
      />
      <ToB
      defaultValue={endDate}
      onChange={(date) => updateRangeDates([startDate, date])}
      />
      {/* <From
        selected={startDate}
        onChange={(date) => updateRangeDates([date, endDate])}
      />
      <To
        selected={endDate}
        onChange={(date) => updateRangeDates([startDate, date])}
      /> */}
    </RangeDate>
  );
}
