import React, { useContext, useRef } from "react";
import { RangeDate, FromB, ToB } from "components/general/RangeDate";
import { ContextInvoiceEmittedV2 } from "..";

export default function Dates() {
  const hook = useContext(ContextInvoiceEmittedV2);

  const defaultFrom = useRef({
    facturas_emitidas: new Date(hook.startDate),
    pedidos: new Date(hook.preinvoice.startDate),
  });

  const defaultTo = useRef({
    facturas_emitidas: new Date(hook.endDate),
    pedidos: new Date(hook.preinvoice.endDate),
  });

  return (
    <RangeDate>
      <div>
        <FromB
          defaultValue={defaultFrom.current[hook.from]}
          onChange={(date) => hook.setFrom(date)}
        />
      </div>

      <div>
        <ToB
          defaultValue={defaultTo.current[hook.from]}
          onChange={(date) => hook.setTo(date)}
        />
      </div>
    </RangeDate>
  );
}
