import React, { useContext } from "react";
import Table from "components/general/Table";
import { ContextInvoiceEmittedV2 } from "..";
import ModalDocsRelatedV2 from "components/general/Documents/ModalDocsRelatedV2";
import { parseToFolio } from "helpers/documents";
import { parseDateToText } from "helpers/dates";

/**
 * Render a table with invoices emitted
 * @param {import('react-data-table-component').TableProps<import('customHooks/useAdministrationInvoice/types').InvoiceI>} props
 * @returns {JSX.Element}
 */
export default function TableInvoiceEmittedV2(props) {
  return <Table {...props} />;
}

export function TableToUse() {
  const hook = useContext(ContextInvoiceEmittedV2);

  if (hook.from === "facturas_emitidas")
    return (
      <TableInvoiceEmittedV2
        sortServer={false}
        data={hook.invoices}
        paginationPerPage={100}
        paginationTotalRows={hook.pages * 100}
        progressPending={hook.state === "loading"}
        onChangePage={(page) => hook.setPage(page)}
        selectableRowSelected={(invoice) => invoice.id === hook.invoice?.id}
        onRowClicked={(invoice) => {
          hook.setInvoice(invoice);
        }}
        columns={[
          {
            name: "No. folio",
            sortable:true,
            sortFunction: (a, b) => +a.documentNumber - +b.documentNumber,
            cell: (invoice) => (
              <ModalDocsRelatedV2
                fromLegal={false}
                idDocument={invoice.idPreinvoice}
              >
                <p
                  onClick={() => hook.setInvoice(invoice)}
                  className="text-center w-100 m-0"
                >
                  {parseToFolio(+invoice.documentNumber)}
                </p>
              </ModalDocsRelatedV2>
            ),
            width: "10%",
            center: true,
          },
          {
            name: "Fecha de emisión",
            cell: (invoice) => (
              <p
                onClick={() => hook.setInvoice(invoice)}
                className="text-center w-100 m-0"
              >
                {parseDateToText(invoice.emited)}
              </p>
            ),
            width: "10%",
            center: true,
          },
          {
            name: "Vencimiento",

            cell: (invoice) => (
              <p
                onClick={() => hook.setInvoice(invoice)}
                className="text-center w-100 m-0"
              >
                {invoice.expirationDateFl}
              </p>
            ),

            width: "10%",
            center: true,
          },
          {
            name: "Ejecutivo",
            cell: (invoice) => (
              <p
                onClick={() => hook.setInvoice(invoice)}
                className="text-center w-100 m-0"
              >
                {invoice.iniciales}
              </p>
            ),

            width: "10%",
            center: true,
          },
          {
            name: "Razón social",
            selector: (invoice) => invoice.razonSocial,
            width: "20%",
            center: true,
          },
          {
            name: "Moneda",

            cell: (invoice) => (
              <p
                onClick={() => hook.setInvoice(invoice)}
                className="text-center w-100 m-0"
              >
                {invoice.moneda}
              </p>
            ),

            width: "10%",
            center: true,
          },
          {
            name: "Total",

            cell: (invoice) => (
              <p
                onClick={() => hook.setInvoice(invoice)}
                className="text-right w-100 m-0"
              >
                {invoice.total.texto}
              </p>
            ),

            width: "10%",
            center: true,
          },
          {
            name: "Saldo CxC",
            cell: (invoice) => (
              <p
                onClick={() => hook.setInvoice(invoice)}
                className="text-right w-100 m-0"
              >
                {invoice.saldo}
              </p>
            ),
            width: "10%",
            center: true,
          },
          {
            name: "Estatus",

            cell: (invoice) => (
              <p
                onClick={() => hook.setInvoice(invoice)}
                className="text-left w-100 m-0"
              >
                {invoice.estatus}
              </p>
            ),

            width: "10%",
            center: true,
          },
        ]}
      />
    );

  if (hook.from === "pedidos")
    return (
      <TableInvoiceEmittedV2
        data={hook.preinvoice.preinvoices}
        paginationPerPage={10}
        paginationTotalRows={hook.preinvoice.pages * 10}
        progressPending={hook.preinvoice.state === "loading"}
        onChangePage={(page) => hook.setPage(page)}
        selectableRowSelected={(invoice) => invoice.id === hook.preinvoice.preinvoice?.id}
        onRowClicked={(invoice) => {
          hook.setInvoice(invoice);
        }}
        columns={[
          {
            name: "No. pedido",
            sortable:true,
            sortFunction: (a, b) => +a.numeroDocumento - +b.numeroDocumento,
            width: "10%",
            center: true,
            cell: (invoice) => (
              <ModalDocsRelatedV2
                fromLegal={false}
                idDocument={invoice.idPreinvoice}
              >
                <p
                  onClick={() => hook.setInvoice(invoice)}
                  className="text-center w-100 m-0"
                >
                  {parseToFolio(+invoice.numeroDocumento)}
                </p>
              </ModalDocsRelatedV2>
            ),
          },
          {
            name: "Facturar",
            cell: (invoice) => (
              <p
                onClick={() => hook.setInvoice(invoice)}
                className="text-center w-100 m-0"
              >
                {invoice.facturar}
              </p>
            ),
            width: "10%",
            center: true,
          },
          {
            name: "Ejecutivo",

            cell: (invoice) => (
              <p
                onClick={() => hook.setInvoice(invoice)}
                className="text-lefet w-100 m-0"
              >
                {invoice.iniciales}
              </p>
            ),

            width: "10%",
            center: true,
          },
          {
            name: "Razón social",
            selector: (invoice) => invoice.razonSocial,
            width: "30%",
            center: true,
          },
          {
            name: "Moneda",

            cell: (invoice) => (
              <p
                onClick={() => hook.setInvoice(invoice)}
                className="text-center w-100 m-0"
              >
                {invoice.moneda}
              </p>
            ),

            width: "10%",
            center: true,
          },
          {
            name: "Total",

            cell: (invoice) => (
              <p
                onClick={() => hook.setInvoice(invoice)}
                className="text-right w-100 m-0"
              >
                {invoice.total.texto}
              </p>
            ),

            width: "10%",
            center: true,
          },
          {
            name: "Saldo CxC",
            cell: (invoice) => (
              <p
                onClick={() => hook.setInvoice(invoice)}
                className="text-right w-100 m-0"
              >
                {invoice.saldo}
              </p>
            ),
            width: "10%",
            center: true,
          },
          {
            name: "Estatus",

            cell: (invoice) => (
              <p
                onClick={() => hook.setInvoice(invoice)}
                className="text-left w-100 m-0"
              >
                {invoice.estatus}
              </p>
            ),

            width: "10%",
            center: true,
          },
        ]}
      />
    );
}
