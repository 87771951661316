import { getInvoicesPaginated } from "helpers/Apis/invoice";
import { getYearRange, dateToLastHours, dateToFirstHours } from "helpers/dates";
import { useEffect, useRef, useState } from "react";
import { getReportInvoiceExcel } from "helpers/Apis/invoice";
import { saveAs } from "file-saver";
import { useHistory } from "react-router-dom";
import useMemoryFilter from "customHooks/useMemoryFilter";

/**
 * @type {import("./types").PropsInvoicesState}
 */
const INITIAL_STATE = {
  page: 1,
  pages: 1,
  socialReason: "",
  startDate: dateToFirstHours(getYearRange().begin.js),
  endDate: dateToLastHours(getYearRange().end.js),
  folio: null,
  status: 20,
  invoices: [],
  triggerSearch: false,
  state: "loading",
  invoice: null,
};

/**
 * @type {import("./types").PropsPreinvoiceState}
 */
const INITIAL_STATE_PREINVOICE = {
  page: 1,
  pages: 1,
  socialReason: "",
  startDate: dateToFirstHours(getYearRange().begin.js),
  endDate: dateToLastHours(getYearRange().end.js),
  folio: null,
  status: 4,
  preinvoices: [],
  triggerSearch: false,
  state: "loading",
  preinvoice: null,
};

/**
 * Handle the invoices and preinvoices of the system
 * @param {import("components/general/Forms/Selects/StatusInvoice/types").FROM_INVOICE} [from="facturas_emitidas"]
 * @returns {import("./types").ReturnUseInvoices}
 */
export default function useInvoices(from = "facturas_emitidas") {
  /**
   * @type {import("./types").Breadcrumb}
   */
  const breadcrum = useRef({
    contabilidad: [],
    pedidos: [
      {
        route: "/inicio",
        text: "Inicio",
      },
      {
        route: "/administracion/pedidos",
        text: "Ventas",
      },
      {
        route: "/administracion/pedidos",
        text: "Pedidos",
      },
    ],
    facturas_emitidas: [
      {
        route: "/inicio",
        text: "Inicio",
      },
      {
        route: "/administracion/facturas-emitidas",
        text: "Ventas",
      },
      {
        route: "/administracion/facturas-emitidas",
        text: "Facturas emitidas",
      },
    ],
  });

  const { retrieveData, setMemoryData } = useMemoryFilter({
    socialReason: "",
    startDate: dateToFirstHours(getYearRange().begin.js),
    endDate: dateToLastHours(getYearRange().end.js),
    folio: null,
    status: 20,
  });

  const preinvoicesMemory = useMemoryFilter({
    socialReason: "",
    startDate: dateToFirstHours(getYearRange().begin.js),
    endDate: dateToLastHours(getYearRange().end.js),
    folio: null,
    status: 4,
  });

  const [state, setState] = useState({
    ...INITIAL_STATE,
    ...retrieveData("invoices"),
  });

  const [statePreinvoice, setStatePreinvoice] = useState({
    ...INITIAL_STATE_PREINVOICE,
    ...preinvoicesMemory.retrieveData("preinvoices"),
  });

  const history = useHistory();

  useEffect(() => {
    setMemoryData(
      {
        socialReason: state.socialReason,
        startDate: state.startDate,
        endDate: state.endDate,
        folio: state.folio,
        status: state.status,
      },
      "invoices"
    );
  }, [
    state.socialReason,
    state.startDate,
    state.endDate,
    state.folio,
    state.status,
  ]);

  useEffect(() => {
    preinvoicesMemory.setMemoryData(
      {
        socialReason: statePreinvoice.socialReason,
        startDate: statePreinvoice.startDate,
        endDate: statePreinvoice.endDate,
        folio: statePreinvoice.folio,
        status: statePreinvoice.status,
      },
      "preinvoices"
    );
  }, [
    statePreinvoice.socialReason,
    statePreinvoice.startDate,
    statePreinvoice.endDate,
    statePreinvoice.folio,
    statePreinvoice.status,
  ]);

  useEffect(() => {
    (async function () {
      if (from !== "facturas_emitidas") return;

      setState((current) => ({
        ...current,
        state: "loading",
        invoice: null,
        invoices: [],
      }));

      const res = await getInvoicesPaginated(
        {
          page: state.page,
          status: state.status,
          customer: state.socialReason === "" ? "" : state.socialReason,
          search: state.folio,
          startDate: state.startDate,
          endDate: state.endDate,
          accounting: null,
        },
        "facturas_emitidas"
      );

      setState((current) => ({
        ...current,
        invoices: res.documents,
        pages: res.pages,
        state: "none",
        page: res.documents.length === 0 ? 1 : current.page,
      }));
    })();
  }, [state.page, state.triggerSearch]);

  useEffect(() => {
    (async function () {
      if (from !== "pedidos") return;

      setStatePreinvoice((current) => ({
        ...current,
        state: "loading",
        preinvoices: [],
        preinvoice: null,
      }));

      const res = await getInvoicesPaginated(
        {
          page: statePreinvoice.page,
          status: statePreinvoice.status,
          customer:
            statePreinvoice.socialReason === "" ? "" : statePreinvoice.socialReason,
          search: statePreinvoice.folio,
          startDate: statePreinvoice.startDate,
          endDate: statePreinvoice.endDate,
          accounting: null,
        },
        "pedidos"
      );

      setStatePreinvoice((current) => ({
        ...current,
        preinvoices: res.documents,
        pages: res.pages,
        state: "none",
        page: res.documents.length === 0 ? 1 : current.page,
      }));
    })();
  }, [statePreinvoice.page, statePreinvoice.triggerSearch]);

  const setSocialReason = (socialReason) => {
    if (from === "facturas_emitidas")
      setState((current) => ({ ...current, socialReason }));

    if (from === "pedidos")
      setStatePreinvoice((current) => ({ ...current, socialReason }));
  };

  /**
   * Set range dates
   * @param {Date} from
   * @param {Date} to
   * @returns {void}
   */
  const setRangeDates = (from, to) =>
    setState((current) => ({
      ...current,
      startDate: dateToFirstHours(from),
      endDate: dateToFirstHours(to),
    }));

  /**
   * Set the status number id
   * @param {number} id
   * @returns {void}
   */
  const setStatus = (id) => {
    if (from === "facturas_emitidas")
      setState((current) => ({ ...current, status: id }));

    if (from === "pedidos")
      setStatePreinvoice((current) => ({ ...current, status: id }));
  };

  /**
   * Set the folio search
   * @param {string} folio
   * @returns {void}
   */
  const setFolio = (folio) => {
    const parsedValue = folio === "" ? null : +folio;

    if (from === "facturas_emitidas")
      setState((current) => ({ ...current, folio: parsedValue }));

    if (from === "pedidos")
      setStatePreinvoice((current) => ({ ...current, folio: parsedValue }));
  };

  const redirectToCreatePreinvoice = () => history.push(`/administracion/pedido/agregar?modulo=${from}`);


  /**
   *
   * @param {null|import("customHooks/useAdministrationInvoice/types").InvoiceI} invoice
   * @returns
   */
  const setInvoice = (invoice) => {
    if (from === "facturas_emitidas")
      setState((current) => ({ ...current, invoice }));

    if (from === "pedidos")
      setStatePreinvoice((current) => ({ ...current, preinvoice: invoice }));
  };

  /**
   * Handle the search of the user
   * @param {React.FormEvent<HTMLFormElement>} e - Event
   */
  const attemptSearch = (e = undefined) => {
    if (e !== undefined) e.preventDefault();

    if (from === "facturas_emitidas")
      setState((current) => ({
        ...current,
        triggerSearch: !current.triggerSearch,
      }));

    if (from === "pedidos")
      setStatePreinvoice((current) => ({
        ...current,
        triggerSearch: !current.triggerSearch,
      }));
  };

  const downloadReport = async () => {
    const excel = await getReportInvoiceExcel("facturas_emitidas", {
      customer: 0,
      endDate: state.endDate,
      page: state.page,
      search: state.folio,
      startDate: state.startDate,
      status: state.status,
    });

    if (excel instanceof Blob) {
      saveAs(excel, `Facturas emitidas.xlsx`);
    }
  };

  const redirectToViewDocument = () => {
    if (from === "facturas_emitidas")
      history.push(
        `../directorio/documentos/${state.invoice.customer.id}/ver/${state.invoice.idPreinvoice}?modulo=${from}`
      );

    if (from === "pedidos")
      history.push(
        `../directorio/documentos/${statePreinvoice.preinvoice.customer.id}/ver/${statePreinvoice.preinvoice.idPreinvoice}?modulo=${from}`
      );
  };

  const redirectToAssociatedFiles = () => {
    const url = from === "pedidos" ? "pedidos" : "facturas-emitidas";

    if (from === "facturas_emitidas")
      history.push(
        `../administracion/archivos-asociados/${url}/${state.invoice.idPreinvoice}`
      );

    if (from === "pedidos")
      history.push(
        `../administracion/archivos-asociados/${url}/${statePreinvoice.preinvoice.idPreinvoice}`
      );
  };

  const redirectToDo = () => {
    if (from === "facturas_emitidas") {
      history.push(
        `../administracion/pedidos/todo?documento=${state.invoice.id}&cliente=${state.invoice.customer.id}`
      );
      return;
    }

    if (from === "pedidos") {
      history.push(
        `../administracion/facturas-emitidas/todo?documento=${statePreinvoice.preinvoice.id}&cliente=${statePreinvoice.preinvoice.customer.id}`
      );
      return;
    }
  };

  /**
   * Set the page of the table paginated
   * @param {number} page - Page
   * @returns {void}
   */
  const setPage = (page) => {
    if (from === "facturas_emitidas")
      setState((current) => ({ ...current, page }));

    if (from === "pedidos")
      setStatePreinvoice((current) => ({ ...current, page }));
  };

  const setFrom = (date) => {
    if (from === "facturas_emitidas")
      setState((current) => ({
        ...current,
        startDate: dateToFirstHours(date),
      }));

    if (from === "pedidos")
      setStatePreinvoice((current) => ({
        ...current,
        startDate: dateToFirstHours(date),
      }));
  };

  const setTo = (date) => {
    if (from === "facturas_emitidas")
      setState((current) => ({
        ...current,
        endDate: dateToLastHours(date),
      }));

    if (from === "pedidos")
      setStatePreinvoice((current) => ({
        ...current,
        endDate: dateToLastHours(date),
      }));
  };

  return {
    setSocialReason,
    setRangeDates,
    setStatus,
    setInvoice,
    attemptSearch,
    setFolio,
    breadcrum,
    downloadReport,
    redirectToViewDocument,
    redirectToAssociatedFiles,
    redirectToDo,
    setPage,
    from,
    setFrom,
    setTo,
    ...state,
    preinvoice: statePreinvoice,
    redirectToCreatePreinvoice
  };
}
