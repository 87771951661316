import ButtonV2 from "components/individual/ButtonsV2/Button";
import React, { useContext } from "react";
import { ContextInvoiceEmittedV2 } from "..";

export default function SearchButton() {
  const hook = useContext(ContextInvoiceEmittedV2);

  const state = {
    facturas_emitidas: hook.state,
    pedidos: hook.preinvoice.state,
  };

  return (
    <ButtonV2 disabled={state[hook.from] === "loading"} type="submit">
      <span>{state[hook.from] === "loading" ? "Buscando" : "Buscar"}</span>
    </ButtonV2>
  );
}
