import React, { useEffect, useState } from "react";
import Datepicker from "react-date-picker";
import { format, parse } from "date-fns";
import es from "date-fns/locale/es";
import { Label } from "structure/Document";

/**
 * Function to format the date as dd/MMM/yyyy
 * @param {Date|number} date
 * @returns {string}
 */
const formatDate = (date) => {
  if (date instanceof Date === false) return "";
  return format(date, "dd/MMM/yyyy", { locale: es });
};

/**
 * Function to parse the formatted date back to a Date object
 * @param {string} str
 * @returns {Date}
 */
const parseDate = (str) => {
  return parse(str, "dd/MMM/yyyy", new Date());
};

/**
 * Render a input of date with the second date calendar desing
 * @param {import("./types").PropsDateInput} props
 * @returns {JSX.Element}
 */
export default function DateInput(props) {
  const [value, setValue] = useState(null);

  const [isCalendarOpen, setIsCalendarOpen] = useState(false);

  const handleInputClick = () => {
    setIsCalendarOpen(true); // Abre el calendario al hacer clic en el input
  };

  const handleCalendarClose = () => {
    setIsCalendarOpen(false); // Cierra el calendario cuando se selecciona una fecha
  };

  useEffect(() => {
    setValue(props?.defaultValue);
  }, [props?.defaultValue]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "0",
      }}
    >
      <Datepicker
        {...props}
        className="hidden-date-picker-input" // Oculta el input predeterminado
        calendarIcon={null}
        clearIcon={null}
        isOpen={isCalendarOpen}
        onCalendarClose={handleCalendarClose}
        onChange={(date, e) => {
          setValue(date);
          handleCalendarClose(); // Cierra el calendario al seleccionar una fecha
          props?.onChange(date, e);
        }}
      />

      {props?.children && <Label required={props.required}>{props.children}</Label>}
      
      <input
        
        type="text"
        value={formatDate(value)}
        onChange={(e) => e.target.value.length === 11 && setValue(parseDate(e.target.value))}
        onClick={handleInputClick}
        placeholder="Selecciona una fecha"
      />
    </div>
  );
}
