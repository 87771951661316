import React, { createContext, useContext, useState } from "react";
import ReactDatePicker, { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import { Label } from "structure/Document";
import { useEffect } from "react";
import DateInput from "components/individual/DateInput";
registerLocale("es", es);

/**
 * State of component
 * @type {import("./types").StateRangeDate}
 */
const INITIAL_STATE = {
  from: null,
  to: null,
};

/**
 * @type {import("./types").ContextRangeDates}
 */
const INITIAL_STATE_CONTEXT = {
  ...INITIAL_STATE,
  setFrom: () => {},
  setTo: () => {},
};

const ContextRangeDates = createContext(INITIAL_STATE_CONTEXT);

/**
 * Render a input to display a range of dates for filter
 * @param {import("./types").PropsRangeDate} props - Props
 * @returns {JSX.Element}
 */
export function RangeDate({ children = <></> }) {
  const [rangeDates, setRangeDates] = useState(INITIAL_STATE);

  const setFrom = (date) =>
    setRangeDates((current) => ({
      ...current,
      from: date,
    }));
  const setTo = (date) =>
    setRangeDates((current) => ({
      ...current,
      to: date,
    }));

  return (
    <ContextRangeDates.Provider
      value={{
        ...rangeDates,
        setFrom,
        setTo,
      }}
    >
      {children}
    </ContextRangeDates.Provider>
  );
}

/**
 * Render the input for `Desde`
 * @param {import("./types").PropsInputRange} props - Props
 * @returns {JSX.Element}
 */
export function From(props) {
  const hook = useContext(ContextRangeDates);

  useEffect(() => {
    hook.setFrom(props.selected);
  }, [props?.selected]);

  return (
    <div>
      <Label required={props.required || false}>Desde</Label>
      <ReactDatePicker
        selected={hook.from}
        dateFormat="dd/MMM/yyyy"
        locale="es"
        placeholderText="Selecciona"
        maxDate={hook.to}
        onChange={(date) => hook.setFrom(date)}
        isClearable={false}
        {...props}
      />
    </div>
  );
}

/**
 * Render the input for `Desde`
 * @param {import("./types").PropsInputRange} props - Props
 * @returns {JSX.Element}
 */
export function To(props) {
  const hook = useContext(ContextRangeDates);

  useEffect(() => {
    hook.setTo(props.selected);
  }, [props?.selected]);

  return (
    <div>
      <Label required={props.required || false}>Hasta</Label>
      <ReactDatePicker
        selected={hook.to}
        dateFormat="dd/MMM/yyyy"
        locale="es"
        placeholderText="Selecciona"
        minDate={hook.from}
        onChange={(date) => hook.setTo(date)}
        isClearable={false}
        {...props}
      />
    </div>
  );
}

/**
 * Render the 2nd idea for the input range dates
 * @param {import("./types").PropsInputRangeB} props
 * @returns {JSX.Element}
 */
export function FromB(props) {
  const hook = useContext(ContextRangeDates);

  useEffect(() => {
    hook.setFrom(props.defaultValue);
  }, [props?.defaultValue]);

  return (
    <DateInput
        value={hook.from}
        {...props}
        onChange={(date) => {
          hook.setFrom(date);
          props.onChange(date);
        }}
        children='Desde'
        maxDate={hook.to}
      />
  );
}

/**
 * Render the 2nd idea for the input range dates
 * @param {import("./types").PropsInputRangeB} props
 * @returns {JSX.Element}
 */
export function ToB(props) {
  const hook = useContext(ContextRangeDates);

  useEffect(() => {
    hook.setTo(props.defaultValue);
  }, [props?.defaultValue]);

  return (
    <DateInput
      value={hook.to}
      {...props}
      onChange={(date) => {
        hook.setTo(date);
        props.onChange(date);
      }}
      children={"Hasta"}
      minDate={hook.from}
    />
  );
}
