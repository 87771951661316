import React, { useContext } from "react";
import ui from "../styles.module.scss";
import { Label } from "structure/Document";
import { Search } from "components/individual/Search";
import { ContextInvoiceEmittedV2 } from "..";

export default function SocialReason() {
  const hook = useContext(ContextInvoiceEmittedV2);

  const value = {
    facturas_emitidas: hook.socialReason,
    pedidos: hook.preinvoice.socialReason,
  }

  return (
    <div className={ui.search}>
      <Label>Razon social</Label>
      <Search
        hideSearchIcon={true}
        value={value[hook.from]}
        onChange={(value) => hook.setSocialReason(value)}
        placeholder="Razón social, nombre corto"
        onDelete={() => hook.setSocialReason("")}
      />
    </div>
  );
}
