import React, { useContext } from "react";
import { Label } from "structure/Document";
import { Search } from "components/individual/Search";
import { ContextInvoiceEmittedV2 } from "..";
import { noDecimals } from "helpers/money";
import ui from '../styles.module.scss';

export default function Folio() {
  const hook = useContext(ContextInvoiceEmittedV2);

  const label = {
    facturas_emitidas: "No. factura",
    pedidos: "No. pedido",
  };

  const value = {
    facturas_emitidas: hook.folio,
    pedidos: hook.preinvoice.folio,
  };

  return (
    <div className={ui.folio}>
      <Label>{label[hook.from]}</Label>
      <Search
        type="number"
        value={value[hook.from]}
        hideSearchIcon={true}
        placeholder="Escribe aquí"
        onDelete={() => hook.setFolio("")}
        onChange={(data) => hook.setFolio(`${noDecimals(data)}`)}
      />
    </div>
  );
}
